<template>
  <div class="with-anchors">
    <h1 class="display-l">Tone of voice</h1>

    <!-- Logo suite -->
    <section id="intro">
        <p>Creating consistency in how we sound allows us to build stronger, more authentic connections with our audiences. That consistency needs to represent our unique and valuable brand personality.</p>

        <p>
            <span style="color:#3F817F">Tone</span><br/>
            Knowledgeable. Trustworthy. Qualified. We need to find a way to convey these traits across products with a tone that’s unique to Intelematics.
        </p>

        <p>
            <span style="color:#3F817F">Consistency</span><br/>
            Tone and language in all our communications must be consistent in order for us to be well understood and truly stand out in the way we want to be perceived.
        </p>

        <p>
            <span style="color:#3F817F">Readability</span><br/>
            Shorter sentences, simplified structure, familiar language and less jargon will draw people in and will help them understand our content.
        </p>

    </section>

    <!-- clear space -->
    <section id="brand-persona">
        <h2 class="display-m" id="brand">Brand persona</h2>
        <p class="section-heading">Who we are when we speak</p>
        <p>We're trusted experts in our field who gladly take the spotlight to move things forward. <br/>
Our technical and cutting-edge work is balanced by a clear and progressive voice. <br/>
We communicate with the spark and confidence of a meaningful collaborator and use our successes to provide insight for our audience. <br/>
We filter our ambitious spirit through a practical lens to give our tone the sense of energy that inspires us to find better, more efficient ways to mobilise the world.</p>

        <p class="section-heading">Traits</p>
        <ul>
            <li>Active </li>
            <li>Energetic </li>
            <li>Aware</li>
            <li>Empathic </li>
            <li>People-centric</li>
        </ul>
        
    </section>

    <section>
         <h2 class="display-m" id="voicemap">VoiceMap™</h2>
         <p>Who we are when we speak</p>
         <p><img src="../../assets/img/voice.png" alt="" role="presentation" width="900" /></p><br/>

         <p class="section-heading">VoiceMap™ Descriptors</p>
         <p>
            <span style="color:#3F817F">Volume</span><br/>
            We’re conversational and human,  no matter the subject.
        </p>
        <p>
            <span style="color:#3F817F">Energy</span><br/>
           Switched-on and upbeat, we speak  with a crisp momentum.
        </p>
        <p>
            <span style="color:#3F817F">Formality</span><br/>
            We’re professionally minded,  but never overly corporate.
        </p>
        <p>
            <span style="color:#3F817F">Attitude</span><br/>
           We confidently share an opinion, standing behind  what we say as trusted experts in our field
        </p>
        <p>
            <span style="color:#3F817F">Accessibility</span><br/>
            We’re smart and speak with  an easy sophistication.
        </p>
        <p>
            <span style="color:#3F817F">Humour</span><br/>
            We’re more about understated wit  than one-liners.
        </p>
    </section>

    <section>
        <h2 class="display-m" id="example">A simple edit can make all the difference</h2>
        <p>Don’t be afraid to remove words and get to the point</p><br/>
        <p><img src="../../assets/img/voice2.png" alt="" role="presentation" width="900"/></p><br/>
    </section>

    <AnchorMenu :anchors="toneMenu"/>

  </div>
</template>

<script>
import AnchorMenu from "../common/AnchorMenu";
import { toneAnchors } from "../../assets/data/anchors";

export default {
  name: "ToneOfVoice",
  components: {
    AnchorMenu,
  },
  data: () => {
    return {
      toneMenu: toneAnchors,
    };
  },
};
</script>
